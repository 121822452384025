import { Stack } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import GuestAppointmentComponent from "../../../components/profile/guestUser/GuestAppointmentComponent";

const GuestAppointmentScreen = () => {
  const isAuthenticated = localStorage.getItem("token");
  const nav = useNavigate();
  useEffect(() => {
    if (isAuthenticated) {
      nav("/appointment");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack width={{ xs: "100vw" }}>
      {!isAuthenticated && <GuestAppointmentComponent />}
    </Stack>
  );
};

export default GuestAppointmentScreen;
