import { Stack } from '@mui/system'
import React from 'react'
import AppointmentComponent from '../../../components/profile/appointment/AppointmentComponent'

const AppointmentScreen = () => {
    return (
        <Stack width={{ xs: "100vw", md: "75vw" }}>
            <AppointmentComponent />
        </Stack>
    )
}

export default AppointmentScreen