import { Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import VehicleCard from "../../reuseable/cards/VehicleCard";
import NewCar from "../../../assets/images/new_car.svg";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import AppointmentCard from "../../reuseable/cards/AppointmentCard";
import { useLocation } from "react-router-dom";

const AppointmentCars = ({ index, setSelectedCar, selectedCar }) => {
  const { carsList } = useSelector((state) => state.garage);
  const location = useLocation();
  const handleCarSelected = (index) => {
    setSelectedCar(index);
    setNewCarSelected(false);
  };

  const [newCarSelected, setNewCarSelected] = useState(false);
  const handleNewCarSelected = (index) => {
    setNewCarSelected(true);
    setSelectedCar(false);
  };
  useEffect(() => {
    if (location?.state?.selectedCar)
      setSelectedCar(location.state.selectedCar);
  }, []);

  return (
    <AppointmentCard>
      <Typography
        fontWeight={"bold"}
        mb="1em"
      >{`${index}. Select a Vehicle`}</Typography>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        overflow={"scroll"}
        py={"2px"}
        width={"100%"}
        pl={"2px"}
        spacing={{ xs: 4, md: 2 }}
      >
        {carsList &&
          carsList.map((car) => {
            return (
              <>
                <VehicleCard
                  image={car.imageURL}
                  text={car.name}
                  index={car._id}
                  handler={handleCarSelected}
                  selected={car._id === selectedCar ? true : false}
                  width="100%"
                />
              </>
            );
          })}
        <VehicleCard
          image={NewCar}
          text="New Car"
          handler={handleNewCarSelected}
          selected={newCarSelected}
          width="100%"
        />
      </Stack>
    </AppointmentCard>
  );
};

export default AppointmentCars;
