import { Card, CardContent, Typography } from "@mui/material";
import React from "react";
import "../../../utils/css/styles.css";
import selectedCar from "../../../assets/images/selected.svg";

const VehicleCard = ({ image, text, selected, handler, index, width }) => {
  return (
    <>
      <Card
        sx={{
          bgcolor: "transparent",
          overflow: "unset",
          height: "11.4375em",
          minWidth: "15.4375em",
          position: "relative",
        }}
        className={selected ? "selectedCar" : ""}
        onClick={() => {
          handler(index);
        }}
      >
        <img
          src={image}
          alt=""
          style={{
            objectFit: "cover",
            width: width || "13.40375em",
            height: "6.8125em",
          }}
        />
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            variant={"caption"}
            color={selected ? "inherit" : "#667085"}
          >
            {text}
          </Typography>
        </CardContent>
        {selected && (
          <img
            src={selectedCar}
            alt=""
            width={"10%"}
            style={{ position: "absolute", top: -4, right: -5 }}
          />
        )}
      </Card>
    </>
  );
};

export default VehicleCard;
