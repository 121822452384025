import styled from "@emotion/styled";
import { LoadingButton } from "@mui/lab";
import { Button, TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { timeSlots } from "../../../data/data";
import { guestAppontment } from "../../../store/appointments/actions";
import ReuseableModal from "../../reuseable/ReuseableModal";
import Lottie from "react-lottie-player";
import GuestAppointmentServices from "./GuestAppointmentServices";
import GuestDateTimePicker from "./GuestDateTimePicker";
import GuestDesiredShop from "./GuestDesiredShop";
import GuestEmailAddress from "./GuestEmailAddress";
import GuestNameField from "./GuestNameField";
import GuestPhoneInput from "./GuestPhoneInput";
import completeAppt from "../../../assets/images/completeAppt.json";
import NewCar from "../../../assets/images/new_car.svg";
import { customerIoEventAnonymous } from "../../../store/dashboard/actions";
import { toast } from "react-hot-toast";
import moment from "moment";
import {
  getCouponDetailAction,
  resetCouponDetailAction,
} from "../../../store/coupon/actions";

const CustomButton = styled(Button)(({ theme }) => ({
  "&:hover": {
    backgroundColor: "red", // your hover styles here
  },
}));
const GuestAppointmentComponent = () => {
  const nav = useNavigate();
  const param = useParams();
  const { shops } = useSelector((state) => state.auth);
  const { appointment, recomended } = useSelector(
    (state) => state.appointments
  );

  const [registerGuest, setRegisterGuest] = useState(false);
  const handleRegisterGuestClose = (appointment) => {
    setRegisterGuest(false);
    if (
      appointment?.referrelCoupon?.redirectUrl &&
      appointment?.referrelCoupon?.redirectStatus === "active"
    ) {
      window.location.href = appointment?.referrelCoupon?.redirectUrl;
    } else {
      nav("/register", {
        state: {
          guest: appointment,
        },
      });
    }
  };
  const handleRegisterAvoidGuestClose = () => {};
  const [startDate, setStartDate] = useState(new Date());
  const { isLoading } = useSelector((state) => state.appointments);
  const [shopDetail, setShopDetail] = useState(null);
  const [timeValue, setTimeValue] = useState(
    shopDetail && shopDetail?.openingHours?.length
      ? shopDetail?.openingHours[0]?.from
      : ""
  );
  const [serviceType, setServiceType] = useState();
  const [shopType, setShopType] = useState();
  const [description, setDescription] = useState("");
  const [phoneValue, setPhoneValue] = useState("");
  const [fullName, setFullName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [outerTimeStamps, setOuterTimeStamps] = useState({
    isEarlyBird: false,
    isAfterHours: false,
  });
  function convertTimeFormat(time) {
    if (!time) {
      toast.error("Please select a time");
    } else {
      const timeArr = time.split(":");
      const hours = parseInt(timeArr[0]);

      const minutes = timeArr[1];
      const period = timeArr[1]?.split(" ")[1];
      const periodValue = period.toUpperCase();

      let convertedHours = hours;
      if (periodValue === "PM" && hours !== 12) {
        convertedHours += 12;
      } else if (periodValue === "AM" && hours === 12) {
        convertedHours = 0;
      }

      return `${convertedHours}:${minutes}`;
    }
  }
  const { register, handleSubmit, formState, control, setValue } = useForm();
  const { errors } = formState;
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    const reff = location.pathname.split("reff/")[1];
    console.log(
      "🚀 ~ file: GuestAppointmentComponent.jsx:78 ~ useEffect ~ reff:",
      reff
    );
    const eventName = "refferal_booking_accessed";
    const payload = {
      data: {
        reffID: reff,
        sharedTime: new Date().toUTCString(),
        deviceType: "web",
        referralCode: param.id,
      },
    };
    dispatch(customerIoEventAnonymous(payload, eventName));
  }, [location]);
  useEffect(() => {
    const reff = sessionStorage.getItem("referral");
    if (reff === location.pathname.split("reff/")[1]) {
      toast.error("You've already filled form, Please Register");
      nav("/register", {
        state: {
          guest: appointment,
        },
      });
    }
  }, []);
  const couponDetail = useSelector((state) => state.Coupon.couponDetail);
  const error = useSelector((state) => state.Coupon.error);

  const onSubmit = (data) => {
    let description = data?.description;
    description = couponDetail?.code
      ? `COUPON CODE IS ${couponDetail?.code}\n DISCOUNT VALUE $${couponDetail?.reward}.00\n description:${description} `
      : description;

    const time = convertTimeFormat(timeValue);

    let convTime = new Date(startDate);
    convTime.setMinutes(0);
    convTime.setSeconds(0);
    convTime.setHours(time.split(" ")[0].split(":")[0]);
    let date = convTime;
    const phoneNumber = data.phoneNumber.replace(/\D+/g, "");
    const reff = location.pathname.split("reff/")[1];
    const payload = {
      tekmetricShopId: data.tekmetricShopId,
      fullName: data.fullName,
      email: data.email,
      phoneNumber: `+${phoneNumber}`,
      appointmentAt: date && date,
      description: description,
      isEarlyBird: outerTimeStamps.isEarlyBird,
      isAfterHours: outerTimeStamps.isAfterHours,
      appointmentReason: data.appointmentReason,
      referralCode: param.id ? param.id : reff,
    };
    dispatch(guestAppontment(payload, setRegisterGuest, reff));
  };
  let shopName;
  let reason;
  if (appointment) {
    shopName = shops.filter(
      (shop) => shop.tekmetricId === appointment?.guestUserInfo?.tekmetricShopId
    );
    reason = recomended.filter(
      (rec) => rec._id === appointment?.appointmentReason
    );
  }
  const dateConvertion = (newDate) => {
    const date = new Date(newDate);
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    const options = {
      month: "long",
      timeZone: "UTC",
    };

    const monthStr = new Date(year, month).toLocaleString("en-US", options);

    return `${day} ${monthStr}, ${year}`;
  };

  const convertTime = (time) => {
    const localDatetimeString = new Date(time).toLocaleString();
    console.log(
      "🚀 ~ file: AppointmentComponent.jsx:169 ~ convertTime ~ date:",
      localDatetimeString,
      moment(time).format("LT")
    );
    // return localDatetimeString.split(",")[1].substr(0,6);
    return moment(time).format("LT");
  };

  const path = window.location.href.split("/");
  const couponCode = path[path.length - 1];

  useEffect(() => {
    dispatch(resetCouponDetailAction());
    // setDescription(null);
  }, [error]);

  useEffect(() => {
    if (couponCode.length > 0) {
      dispatch(getCouponDetailAction({ couponCode }));
    }
  }, [window.location.href]);

  //   useEffect(() => {
  //     if (couponDetail?.code && error === null) {
  //       setDescription(`COUPON CODE IS ${couponDetail?.code}
  // DISCOUNT VALUE $${couponDetail?.reward}.00`);
  //     }
  //   }, [couponDetail]);
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack
          padding={"3em 2em"}
          spacing={4}
          alignItems={"center"}
          width={"100%"}
        >
          <Stack spacing={4} width={"100%"}>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography variant="h4" color={"#344054"}>
                Book Appointment
              </Typography>
            </Stack>
            <GuestDesiredShop
              serviceType={shopType}
              setServiceType={setShopType}
              register={register}
              error={errors}
              setShopDetail={setShopDetail}
              setValue={setValue}
            />
            <GuestDateTimePicker
              startDate={startDate}
              setStartDate={setStartDate}
              timeValue={timeValue}
              setTimeValue={setTimeValue}
              outerTimeStamps={outerTimeStamps}
              setOuterTimeStamps={setOuterTimeStamps}
              shopDetail={shopDetail}
            />
            <GuestAppointmentServices
              serviceType={serviceType}
              setServiceType={setServiceType}
              register={register}
              error={errors}
            />

            <TextField
              id="outlined-multiline-static"
              label="Anything else we should know? (optional)"
              multiline
              rows={4}
              sx={{ bgcolor: "#ffffff" }}
              value={description}
              {...register("description")}
              error={errors && errors.description}
              onChange={(e) => setDescription(e.target.value)}
              fullWidth
            />

            <GuestPhoneInput
              label="+1 (XXX) XXX-XXXX "
              phoneValue={phoneValue}
              setPhoneValue={setPhoneValue}
              variant="outlined"
              register={register}
              error={errors}
            />
            <GuestNameField
              fullName={fullName}
              setFullName={setFullName}
              register={register}
              error={errors}
            />
            <GuestEmailAddress
              emailAddress={emailAddress}
              setEmailAddress={setEmailAddress}
              register={register}
              error={errors}
            />
          </Stack>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            type="submit"
            size="large"
            sx={{
              borderRadius: "54.6591px",
              background:
                "linear-gradient(80.67deg, #C42E66 27.97%, #D23852 96.9%)",
              fontSize: "small",
              p: 2,
              width: "100%",
            }}
          >
            Complete Booking
          </LoadingButton>
        </Stack>
      </form>
      {appointment && (
        <ReuseableModal
          handleClose={handleRegisterAvoidGuestClose}
          open={registerGuest}
          width={"40%"}
        >
          {/* <img src={bookingCreated} alt="" width={"50%"} /> */}
          <Stack mt={"-50px"}>
            <Lottie
              loop
              animationData={completeAppt}
              play
              style={{ width: "100%", height: 200 }}
            />
          </Stack>
          <Typography
            variant="h6"
            color={"#C42E66"}
            sx={{ marginTop: "-2em !important" }}
          >
            Congratulations
          </Typography>
          <Typography variant="h6" color={"#000000"} mt={"0px !important"}>
            Your Appointment has been booked
          </Typography>
          <Typography variant="subtitle2">{`Integrity 1st Automotive ${shopName[0]?.address?.city},${shopName[0]?.address?.state}  is will be waiting for you`}</Typography>
          <Stack
            direction={{ xs: "row", sm: "column", md: "row" }}
            alignItems={{ xs: "start", sm: "center", md: "start" }}
            justifyContent={"space-between"}
            width={"100%"}
            borderTop={"1px solid gray"}
            paddingY={1}
            borderBottom={"1px solid gray"}
          >
            <Typography variant="subtitle2">Appointment Date</Typography>
            <Typography variant="subtitle2">
              {`${moment(appointment?.appointmentAt).format("MMMM Do YYYY")}`}
            </Typography>
          </Stack>
          <Stack
            direction={{ xs: "row", sm: "column", md: "row" }}
            alignItems={{ xs: "start", sm: "center", md: "start" }}
            justifyContent={"space-between"}
            width={"100%"}
            mt="0px !important"
            paddingY={1}
            borderBottom={"1px solid gray"}
          >
            <Typography variant="subtitle2">Appointment Time</Typography>
            <Typography variant="subtitle2">
              {/* {`${convertTime(
                appointment?.appointmentAt?.split("T")[1].slice(0, 5)
              )}`} */}
              {appointment?.isEarlyBird
                ? "Early Bird"
                : appointment?.isAfterHours
                ? "After hours drop off"
                : `${moment(appointment?.appointmentAt).format("hh:mm a")}`}
            </Typography>
          </Stack>
          <Typography alignSelf={"start"} fontWeight={"bold"}>
            Summary
          </Typography>
          <Stack
            direction={{ xs: "row", sm: "column", md: "row" }}
            width={"100%"}
            spacing={2}
          >
            <Stack
              borderRadius={"20px"}
              width={{ xs: "30%", sm: "100%", md: "30%" }}
            >
              <img
                src={NewCar}
                alt=""
                height={"100%"}
                style={{ objectFit: "contain" }}
              />
            </Stack>
            <Stack spacing={1} flexGrow={1}>
              <Typography fontWeight={"bold"}>New Car</Typography>
              <Typography color={"#DFB11E"}>{reason[0]?.title}</Typography>
              <Typography variant="caption" color="#20282B">
                {appointment?.description}
              </Typography>
            </Stack>
          </Stack>
          {appointment?.rewardValue ? (
            <Stack
              direction={{ xs: "row", sm: "column", md: "row" }}
              alignItems={{ xs: "start", sm: "center", md: "start" }}
              justifyContent={"space-between"}
              width={"100%"}
              borderTop={"1px solid gray"}
              paddingY={1}
              borderBottom={appointment?.rewardValue > 0 && "1px solid gray"}
            >
              {appointment?.rewardValue > 0 && (
                <>
                  <Typography variant="subtitle2" color={"#787B82"}>
                    REWARD ON ACCOUNT CREATION & SERVICE COMPLETION
                  </Typography>
                  <Typography variant="subtitle2" color={"#787B82"}>
                    ${appointment?.rewardValue}
                  </Typography>
                </>
              )}
            </Stack>
          ) : null}

          <Stack direction={"row"} width="100%" spacing={2}>
            <CustomButton
              sx={{
                borderRadius: "54.6591px",
                bgcolor: "#F83D4B",
                fontSize: "small",
                p: 2,
                color: "white",
                width: "100%",
              }}
              onClick={() => handleRegisterGuestClose(appointment)}
            >
              {appointment?.referrelCoupon?.redirectStatus === "active"
                ? "Go Back"
                : "Complete Registration"}
            </CustomButton>
          </Stack>
        </ReuseableModal>
      )}
    </>
  );
};

export default GuestAppointmentComponent;
